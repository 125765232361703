<template>
<div>
 <PromoView :propContents="contents" :watchAction="watchContent" :getTitle="showPremieringDate" />
 </div>
</template>

<script>
/** CODE COMMENTS TO BE ADDED.
 * Leftovers:
 * animatingTime to be configured.
 */
import {mapActions} from 'vuex';
import {EventBus} from '@/main';
import PromoView from '@/components/Shared/templates/promoview.vue';
let unique=(list,prop)=>{
    let uniquelist=[],mapper={};
    for(let content of list){
        if(mapper[content[prop]])continue;
        else {
            uniquelist.push(content);
            mapper[content[prop]]=true;
        }
    }
    return uniquelist;
}
let monthNames=['January','February','March','April',
                'May','June','July','August',
                'September','October','November','December'];
export default {
 data(){
     return {
         contents:[]
     }
 },
 methods:{
    ...mapActions(['actListPremiers']),
    fetchContents(){
        let data={promotype:'POSTER',orientation:'LANDSCAPE'};
        let screenWidth=document.documentElement.clientWidth||window.screen.availWidth;
        data.quality=screenWidth<780?'SD':'HD';

        this.actListPremiers(data).then(res=>{
            let contents=unique(res,'contentid');

            if(!(contents && contents.length))throw new Error('no contents');
            
            this.contents=contents;
        }).catch((err)=>{
            console.log(err);
            this.$router.replace({name:'Home'})
        })
    },
    watchContent({contentid}={}){
        this.$router.push({name:'Home'});
        this.$router.push({
          name:'Home/Card',
          params:{contentid}
        });
    },
    showPremieringDate(content){
         let title={};
         title.name='Premiering on:';
         valuesetter:{   
            let date=content && content.fromdate;
            if(!date)break valuesetter;
            let regex=/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/;
            let match=regex.exec(date);
            if(!match)break valuesetter;
            let {year,month,day}=match.groups;
            month=Number(month);
            if(!isNaN(month))month=monthNames[month-1];
            title.value=`${day} ${(month)} ${year}`
         }
         return title;
    }
 },
 components:{
    PromoView:PromoView
 },
 created(){
     this.fetchContents();
 }
}
</script>
